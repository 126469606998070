.async_loading {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
 
}
.async_loading > img {
  width: 100px;
}